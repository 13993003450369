// @ts-nocheck
'use client'
import {motion, type HTMLMotionProps, type Variants} from 'framer-motion'
import Image from 'next/image'
import Link from 'next/link'

type MotionH2Props = HTMLMotionProps<'h2'>
type MotionSpanProps = HTMLMotionProps<'span'>

const MotionH2 = motion.h2 as React.FC<MotionH2Props>
const MotionSpan = motion.span as React.FC<MotionSpanProps>

const letterAnimation: Variants = {
  initial: {opacity: 0, y: 50},
  whileInView: {opacity: 1, y: 0},
}

const fadeInUp = {
  initial: {opacity: 0, y: 20},
  animate: {opacity: 1, y: 0},
  transition: {duration: 0.5},
}

const titleAnimation = {
  initial: {opacity: 0, y: 20},
  whileInView: {opacity: 1, y: 0},
  transition: {duration: 0.5},
}

const FancyTitle = ({text, className = ''}: {text: string; className?: string}) => (
  <MotionH2
    initial='initial'
    whileInView='whileInView'
    viewport={{once: true}}
    className={`relative text-5xl font-bold ${className}`}>
    <span className='absolute -inset-1 blur-2xl bg-gradient-to-r from-blue-600/30 to-purple-600/30' />
    <div className='relative flex gap-4 items-baseline'>
      {text.split('').map((letter, i) => (
        <MotionSpan
          key={i}
          variants={letterAnimation}
          className='inline-block bg-clip-text text-transparent bg-gradient-to-r from-blue-400 via-purple-400 to-blue-400 hover:scale-110 transition-transform cursor-default'>
          {letter}
        </MotionSpan>
      ))}
    </div>
  </MotionH2>
)

const Header = () => (
  <header className='fixed top-0 w-full bg-gradient-to-r from-black/80 to-gray-900/80 backdrop-blur-md z-50 border-b border-gray-700'>
    <nav className='max-w-7xl mx-auto px-4 py-4 flex justify-between items-center'>
      <Link href='/' className='flex items-center gap-2 hover:scale-105 transition-transform'>
        <Image
          src='/img/logo-text.png'
          alt='liviogama logo'
          width={120}
          height={40}
          className='brightness-200'
        />
      </Link>
      <div className='hidden md:flex gap-8'>
        {['About', 'Projects', 'MBTI', 'Experience'].map(item => (
          <Link
            key={item}
            href={`/#${item.toLowerCase()}`}
            className='text-gray-300 hover:text-white hover:scale-110 transition-all relative group'>
            {item}
            <span className='absolute -bottom-1 left-0 w-0 h-0.5 bg-blue-500 group-hover:w-full transition-all' />
          </Link>
        ))}
      </div>
    </nav>
  </header>
)

const SocialLink = ({href, icon}: {href: string; icon: string}) => {
  const MotionLink = motion(Link)
  const labels = {
    linkedin: 'LinkedIn profile of Livio Gamassia',
    twitter: 'Twitter profile of Livio Gama',
    instagram: 'Instagram profile of DJ Livio Gama',
  }
  return (
    <MotionLink
      href={href}
      target='_blank'
      rel='noopener noreferrer'
      whileHover={{scale: 1.2, rotate: 5}}
      aria-label={labels[icon as keyof typeof labels]}
      className='w-12 h-12 flex items-center justify-center rounded-full bg-gradient-to-br from-blue-600 to-purple-600 text-white hover:from-blue-700 hover:to-purple-700 transition-colors'>
      <i className={`fa-brands fa-${icon} text-xl`} aria-hidden='true' />
    </MotionLink>
  )
}

const About = () => (
  <section
    id='about'
    className='relative min-h-screen flex items-center bg-gradient-to-b from-black to-gray-900 text-white overflow-hidden'>
    <div className='absolute inset-0 bg-[url("/img/grid.svg")] bg-center [mask-image:linear-gradient(180deg,white,rgba(255,255,255,0))]' />
    <div className='relative max-w-4xl mx-auto px-4 py-20'>
      <motion.div
        initial={{opacity: 0, y: 20}}
        whileInView={{opacity: 1, y: 0}}
        transition={{duration: 0.8}}
        className='space-y-12'>
        <FancyTitle text='About' />
        <p className='text-xl text-gray-300 leading-relaxed'>
          Often, in my sector (tech companies), IT is perceived as a black box. To the uninitiated,
          developers spend their days writing 0s and 1s, and the result is something similar to dark
          magic. This situation leads to fragmentation within the company's various departments,
          particularly between management and technical operations.
        </p>
        <div className='flex flex-col md:flex-row gap-12 items-center'>
          <motion.div whileHover={{scale: 1.05}} className='relative group'>
            <div className='absolute -inset-1 bg-gradient-to-r from-blue-600 to-purple-600 rounded-lg blur opacity-75 group-hover:opacity-100 transition duration-1000 group-hover:duration-200' />
            <Image
              src='/img/livio.webp'
              alt='Livio Gamassia'
              width={300}
              height={300}
              className='relative rounded-lg'
            />
          </motion.div>
          <div className='space-y-6'>
            <h3 className='text-3xl font-semibold'>Livio Gamassia</h3>
            <p className='text-xl text-gray-300'>
              Senior Software Engineer - Freelance, based in Lausanne, Switzerland
            </p>
            <div className='flex gap-4'>
              <SocialLink href='https://linkedin.com/in/livio-gamassia' icon='linkedin' />
              <SocialLink href='https://twitter.com/liviogama' icon='twitter' />
              <SocialLink href='https://instagram.com/djliviogama' icon='instagram' />
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  </section>
)

const Projects = () => {
  const MotionLink = motion(Link)
  return (
    <section id='projects' className='relative bg-black py-20'>
      <div className='absolute inset-0 bg-[url("/img/grid.svg")] bg-center [mask-image:linear-gradient(0deg,white,rgba(255,255,255,0))]' />
      <div className='relative max-w-4xl mx-auto px-4'>
        <FancyTitle text='Projects' className='mb-12' />
        <div className='grid grid-cols-1 md:grid-cols-2 gap-8'>
          <MotionLink
            href='https://ai.liviogama.com'
            target='_blank'
            rel='noopener noreferrer'
            initial={{opacity: 0, x: -20}}
            whileInView={{opacity: 1, x: 0}}
            whileHover={{scale: 1.05}}
            className='group relative'>
            <div className='absolute -inset-1 bg-gradient-to-r from-blue-600 to-purple-600 rounded-lg blur opacity-75 group-hover:opacity-100 transition duration-1000 group-hover:duration-200' />
            <div className='relative p-8 bg-gray-900 rounded-lg'>
              <h3 className='text-2xl font-semibold text-white mb-4'>AI Version of Myself</h3>
              <p className='text-gray-300'>
                Check out my latest project - an AI-powered version of myself!
              </p>
            </div>
          </MotionLink>
          <MotionLink
            href='https://project.liviogama.com'
            target='_blank'
            rel='noopener noreferrer'
            initial={{opacity: 0, x: 20}}
            whileInView={{opacity: 1, x: 0}}
            whileHover={{scale: 1.05}}
            className='group relative'>
            <div className='absolute -inset-1 bg-gradient-to-r from-blue-600 to-purple-600 rounded-lg blur opacity-75 group-hover:opacity-100 transition duration-1000 group-hover:duration-200' />
            <div className='relative p-8 bg-gray-900 rounded-lg'>
              <h3 className='text-2xl font-semibold text-white mb-4'>Business Assessment Tool</h3>
              <p className='text-gray-300'>A powerful tool for business assessment and learning</p>
            </div>
          </MotionLink>
        </div>
      </div>
    </section>
  )
}

const MBTI = () => (
  <section id='mbti' className='relative bg-black py-20'>
    <div className='absolute inset-0 bg-[url("/img/grid.svg")] bg-center [mask-image:linear-gradient(0deg,white,rgba(255,255,255,0))]' />
    <div className='relative max-w-4xl mx-auto px-4'>
      <FancyTitle text='MBTI' className='mb-12' />
      <motion.div
        initial={{opacity: 0, y: 20}}
        whileInView={{opacity: 1, y: 0}}
        className='space-y-6 text-gray-300'>
        <p>
          In terms of personality testing, I am according to the Myers-Briggs Type Indicator tool an
          ENTJ profile with the Te Ni Se Fi cognitive functions.
        </p>
        <div className='grid grid-cols-1 md:grid-cols-2 gap-8'>
          <motion.div
            initial={{opacity: 0, x: -20}}
            whileInView={{opacity: 1, x: 0}}
            className='space-y-4 p-6 bg-gray-900/50 rounded-lg border border-gray-800'>
            <h3 className='text-xl font-semibold text-blue-400'>Primary Functions</h3>
            <div className='space-y-4'>
              <p>
                <span className='text-blue-400 font-semibold'>Te:</span> Helps me to choose what is
                best for the group, not necessarily for myself, and reveals my management qualities.
              </p>
              <p>
                <span className='text-blue-400 font-semibold'>Ni:</span> My library of knowledge
                accumulated in several years of career which allows me to extrapolate by linear
                interpolation all the technical subjects.
              </p>
              <p>
                <span className='text-blue-400 font-semibold'>Se:</span> Allows me to travel the
                world and to fill my Ni with the experience of the field, without locking me in
                introverted beliefs.
              </p>
              <p>
                <span className='text-blue-400 font-semibold'>Fi:</span> Contains my values, my
                humanity and finally intervenes little in the professional environment where the
                collective is privileged over individualism.
              </p>
            </div>
          </motion.div>
          <motion.div
            initial={{opacity: 0, x: 20}}
            whileInView={{opacity: 1, x: 0}}
            className='space-y-4 p-6 bg-gray-900/50 rounded-lg border border-gray-800'>
            <h3 className='text-xl font-semibold text-purple-400'>Areas of Improvement</h3>
            <div className='space-y-4'>
              <p>
                <span className='text-purple-400 font-semibold'>Ti:</span> Should help me to reason
                in a deductive way instead of inductive way. Induction is faster but does not
                guarantee 100% accuracy.
              </p>
              <p>
                <span className='text-purple-400 font-semibold'>Ne:</span> The process that allows
                me to see several possible solutions, to explore, to imagine and finally which is an
                engine of innovation.
              </p>
              <p>
                <span className='text-purple-400 font-semibold'>Si:</span> Currently my blind spot,
                will be my ability to be meticulous on the details of reporting and administrative
                style.
              </p>
              <p>
                <span className='text-purple-400 font-semibold'>Fe:</span> Currently in demonic
                function, which will make me lack judgement in environments where compassion is
                necessary.
              </p>
            </div>
          </motion.div>
        </div>
      </motion.div>
    </div>
  </section>
)

const ExperienceCard = ({
  year,
  title,
  company,
  location,
  description,
  tasks,
}: {
  year: string
  title: string
  company?: {name: string; url?: string}
  location: string
  description?: string
  tasks?: string[]
}) => (
  <motion.div
    initial={{opacity: 0, y: 20}}
    whileInView={{opacity: 1, y: 0}}
    className='relative group'>
    <div className='absolute -inset-1 bg-gradient-to-r from-blue-600/20 to-purple-600/20 rounded-lg blur opacity-75 group-hover:opacity-100 transition duration-1000 group-hover:duration-200' />
    <div className='relative p-6 bg-gray-900/50 rounded-lg border border-gray-800'>
      <div className='flex flex-col md:flex-row justify-between mb-4'>
        <div>
          <h3 className='text-xl font-semibold text-white'>{title}</h3>
          {company && (
            <p className='text-blue-400'>
              @ {company.url ? <a href={company.url}>{company.name}</a> : company.name}
            </p>
          )}
        </div>
        <div className='text-right'>
          <p className='text-gray-400'>{year}</p>
          <p className='text-gray-500'>{location}</p>
        </div>
      </div>
      {description && <p className='text-gray-300 mb-4'>{description}</p>}
      {tasks && tasks.length > 0 && (
        <ul className='list-disc list-inside space-y-2 text-gray-300'>
          {tasks.map((task, i) => (
            <li key={i}>{task}</li>
          ))}
        </ul>
      )}
    </div>
  </motion.div>
)

const Experience = () => (
  <section id='experience' className='relative bg-gradient-to-b from-black to-gray-900 py-20'>
    <div className='absolute inset-0 bg-[url("/img/grid.svg")] bg-center [mask-image:linear-gradient(180deg,white,rgba(255,255,255,0))]' />
    <div className='relative max-w-4xl mx-auto px-4'>
      <FancyTitle text='Experience' className='mb-12' />
      <div className='space-y-8'>
        <ExperienceCard
          year='Aug 2023 - Present'
          title='Senior Software Engineer'
          company={{name: 'Self-employed'}}
          location='Remote'
          tasks={[
            'Full-Stack Development',
            'Mobile Application Development',
            'Technical Architecture Design',
            'Project Management',
          ]}
        />
        <ExperienceCard
          year='Jan 2022 - Feb 2024'
          title='Co-founder & CTO'
          company={{name: 'Heavnn 🏔️', url: 'https://heavnn.io'}}
          location='Lausanne'
          description='We plan your international taxes before they plan you.'
        />
        <ExperienceCard
          year='2018-2022'
          title='CTO'
          company={{name: 'Coteries SA', url: 'https://coteries.com'}}
          location='Lausanne'
          tasks={[
            'Product side: Cedille.ai, Local-Heroes, Planify',
            'Project estimations and specifications',
            'Conception and idea simplification',
            'MVP preparation, offers review',
            'Technology and architecture choices',
            'Projects digitalisation and management',
            'Guiding and teaching the development team',
            'Quality processes: automation, code review, QA',
          ]}
        />
        <ExperienceCard
          year='2016-2017'
          title='Senior iOS developer'
          company={{name: 'WeMoms (Globalia)', url: 'https://wemoms.com'}}
          location='Paris'
          description='Development and continuous improvement of the product WeMoms iOS, a social network for mothers. Featured twice by the App Store.'
          tasks={[
            'Full project migration to Swift',
            'iPad and Apple Watch adaptation',
            'Deep work on the esthetic and animations',
            'Setup development best practices',
            'Setup of CI/CD with easy app distribution',
          ]}
        />
        <ExperienceCard
          year='2015-2016'
          title='Senior iOS/Android developer'
          company={{
            name: 'GoBeyond Studio (formerly MyStudioFactory)',
            url: 'https://studio.gobeyondpartners.com',
          }}
          location='Paris'
          tasks={[
            'Crédit Agricole Ma Carte',
            'Louis Vuitton Grand Palais',
            'Optim Mobile (CACIB) iOS',
            'ING Direct iOS',
          ]}
        />
      </div>
    </div>
  </section>
)

const Contact = () => (
  <section className='relative bg-gradient-to-t from-black to-gray-900 text-white'>
    <div className='max-w-4xl mx-auto px-4 py-20'>
      <FancyTitle text='Contact' className='mb-12' />
      <div className='grid grid-cols-1 md:grid-cols-2 gap-12'>
        <motion.div
          initial={{opacity: 0, x: -20}}
          whileInView={{opacity: 1, x: 0}}
          className='space-y-6'>
          <h3 className='text-2xl font-semibold'>Get in touch</h3>
          <p className='text-gray-300'>
            Email:{' '}
            <a
              href='mailto:livio.gamassia@gmail.com'
              className='text-blue-400 hover:text-blue-300 transition-colors'>
              livio.gamassia@gmail.com
            </a>
          </p>
          <p className='text-gray-300'>
            Phone:{' '}
            <a
              href='tel:+33687599143'
              className='text-blue-400 hover:text-blue-300 transition-colors'>
              +33 687 59 91 43
            </a>
          </p>
        </motion.div>
        <motion.div
          initial={{opacity: 0, x: 20}}
          whileInView={{opacity: 1, x: 0}}
          className='space-y-6'>
          <h3 className='text-2xl font-semibold'>Address</h3>
          <p className='text-gray-300 leading-relaxed'>
            Livio Gamassia
            <br />
            Route du Flon 24A
            <br />
            1610, Oron-la-Ville
            <br />
            Switzerland
          </p>
        </motion.div>
      </div>
    </div>
  </section>
)

export default function Home() {
  return (
    <>
      <Header />
      <main className='bg-black'>
        <About />
        <Projects />
        <MBTI />
        <Experience />
        <Contact />
      </main>
    </>
  )
}
